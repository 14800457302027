import React, { useEffect, useRef, useState } from "react";

const CountUp = ({ targetNumber }) => {
  const [isInView, setIsInView] = useState(false);
  const spanRef = useRef(null);
  const [displayNumber, setDisplayNumber] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (spanRef.current) {
      observer.observe(spanRef.current);
    }

    return () => {
      if (spanRef.current) {
        observer.unobserve(spanRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      let start = 0;
      const duration = 2000; // 2 seconds
      const increment = targetNumber / (duration / 16);

      const animate = () => {
        start += increment;
        if (start < targetNumber) {
          setDisplayNumber(Math.ceil(start));
          requestAnimationFrame(animate);
        } else {
          setDisplayNumber(targetNumber);
        }
      };

      animate();
    }
  }, [isInView, targetNumber]);

  return <span ref={spanRef}>{displayNumber}</span>;
};

export default CountUp;
