import "./LabsDom.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Vantagens from "../../components/Vantagens/Vantagens";
import img from "../../src-assets/covid-vaccine-fight-illness 2.jpg";

const LabsDom = () => {

  const items = [
    {
      icon: "icon5",
      title: "Agendamento simplificado",
      subtitle: "Permita que os pacientes agendem serviços de coleta e vacinação domiciliar de forma fácil e conveniente.",
    },
    {
      icon: "icon9",
      title: "Logística otimizada",
      subtitle: "Planeje e monitore as visitas domiciliares para garantir que sua equipe atenda aos pacientes no tempo certo e com a máxima eficiência.",
    },
    {
      icon: "icon8",
      title: "Comunicação eficiente",
      subtitle: "Mantenha uma comunicação fluida entre técnicos, gestores e pacientes, garantindo um serviço de alta qualidade.",
    },
  ];

  return (
    <>
      <Header />

      <main class="LabsDom">
          <div class="img-texto">
            <div class="div-img">
              <img src="/assets/icons/house.svg" alt="icone de casa"/>
            </div>
            <div class="texto">
              <h3>Para Laboratórios com Serviço Domiciliar</h3>
              <p>
                As soluções da GEEO integram dados e processos para melhorar a eficiência,
                comunicação e qualidade do atendimento,
                ajudando sua empresa a oferecer serviços de saúde domiciliar de forma mais eficaz e estratégica.
              </p>
            </div>
          </div>
          <Vantagens
            title="Vantagens"
            description="Facilite e agilize seus serviços de vacinação e coleta domiciliar!"
            items={items}
          />
          <section class="lastContent">
            <div class="lastText">
              <h2>Utilidades adicionais</h2>
              <h3>Gestão de Coletas Domiciliares</h3>
              <p>
                Utilize a GEEO para agendar e coordenar coletas domiciliares, 
                garantindo que os técnicos tenham todas as informações necessárias 
                e que os pacientes sejam atendidos no horário marcado.
              </p>
              <h3>Comunicação e Logística para Vacinação Domiciliar</h3>
              <p>
                Planeje campanhas de vacinação domiciliar, garantindo que os técnicos estejam equipados com 
                todas as vacinas necessárias e que os pacientes recebam notificações de seus horários de 
                atendimento.
              </p>
              <h3>Relatórios de Serviços Prestados</h3>
              <p>
                Gere relatórios detalhados sobre os serviços prestados, 
                permitindo uma análise precisa da eficiência 
                e identificando áreas para melhoria.
              </p>
            </div>
            <div class="LabsDomImg">
              <img src={img} alt="img" />
            </div>
          </section>
        </main>
      <Footer />
    </>
  );
};

export default LabsDom;
