import "./FAQAccordion.css";
import { useState } from "react";

const FAQAccordion = ({ title, answer }) => {
  const [toggleCollapse, setToggleCollapse] = useState(true);

  return (
    <div
      className="FAQAccordion"
      onClick={() => setToggleCollapse(!toggleCollapse)}
    >
      <h3>
        {title}
        <img
          src="/assets/icons/caret-down.svg"
          alt="ícone de seta para baixo indicando a possibilidade de expandir o cartão"
          style={
            toggleCollapse
              ? {}
              : { transform: "translateY(-50%) rotate(180deg)" }
          }
        />
      </h3>
      <p style={toggleCollapse ? { display: "none" } : { display: "block" }}>
        {answer}
      </p>
    </div>
  );
};

export default FAQAccordion;
