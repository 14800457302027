import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="boundaries">
        <img src="/assets/img/lettering-white.png" alt="logo da marca" />

        <div>
          <a
            href="https://wa.me/+5527996197164"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/icons/whatsapp.svg" alt="icone do whatsapp" />
            <span>(27) 99619-7164</span>
          </a>
          <a
            href="mailto:comercial@geeo.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/icons/mail.svg" alt="icone do whatsapp" />
            <span>comercial@geeo.com.br</span>
          </a>
        </div>

        <div>
          <small>
            &copy;{new Date().getFullYear()} Geeo Logística & Pessoas - Todos os
            direitos reservados
          </small>
          <small>
            Avenida Jeronimo Monteiro, n.o 1000, sala 1503, Ed. Trade Center,
            Centro, Vitória/ES, CEP: 29.010-935
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
