import "./Multi.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Vantagens from "../../components/Vantagens/Vantagens";
import img from "../../src-assets/businessHands.jpg";

const Multi = () => {
  const items = [
    {
      icon: "icon5",
      title: "Agendamento centralizado",
      subtitle:
        "Gerencie consultas de múltiplas especialidades em uma plataforma única e intuitiva",
    },
    {
      icon: "icon6",
      title: "Registros clínicos eletrônicos",
      subtitle:
        "Mantenha um histórico detalhado e seguro das evoluções clínicas dos pacientes",
    },
    {
      icon: "icon7",
      title: "Comunicação entre profissionais",
      subtitle:
        "Facilite a troca de informações entre diferentes profissionais de saúde para um atendimento mais coeso e eficaz",
    },
  ];

  return (
    <>
      <Header />

      <main class="Multi">
        <div class="img-texto">
          <div class="div-img">
            <img src="/assets/icons/stethoscope.svg" alt="icone de estetoscópio"/>
          </div>
          <div class="texto">
            <h3>Para Clínicas Multidisciplinares</h3>
            <p>
              As clínicas multidisciplinares enfrentam desafios únicos na gestão de pacientes e na coordenação 
              de várias especialidades. A GEEO oferece uma plataforma completa para melhorar a eficiência e a 
              qualidade do atendimento em sua clínica.
            </p>
          </div>
        </div>
        <Vantagens
          title="Vantagens"
          description="Conte com a Geeo para proporcionar vantagens para a gestão e otimização de comunicação em sua empresa!"
          items={items}
        />
        <section class="lastContent">
          <div class="lastText">
            <h2>Utilidades adicionais</h2>
            <h3>Coordenação de Consultas e Tratamentos</h3>
            <p>
              Agende consultas para diferentes especialidades e gerencie os tratamentos de forma integrada, 
              garantindo que todos os profissionais envolvidos tenham acesso às informações necessárias.
            </p>
            <h3>Gestão de Protocolos Clínicos</h3>
            <p>
              Implementação de protocolos clínicos padronizados para garantir que todos os profissionais sigam as 
              mesmas diretrizes, melhorando a qualidade do atendimento.
            </p>
            <h3>ompartilhamento de Informações com Pacientes</h3>
            <p>
              Facilite o acesso dos pacientes às suas informações de saúde e aos resultados de exames, 
              promovendo transparência e engajamento.
            </p>
          </div>
          <div class="MultiImg">
            <img src={img} alt="img" />
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Multi;
