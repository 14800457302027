import "./Header.css";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useState, useEffect } from "react";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 667) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <header>
      <div className="boundaries">
        <Link to="/">
          <img src="/assets/img/lettering.svg" alt="logo da marca" />
        </Link>
        <div
          style={
            !isMobile || showDropdown
              ? { display: "flex" }
              : { display: "none" }
          }
        >
          <HashLink smooth to="/#customer-fields">
            Soluções
          </HashLink>
          <HashLink smooth to="/#product">
            Produto
          </HashLink>
          <Link to="/sobre-nos">Sobre Nós</Link>
          <Link to="/faq">FAQ</Link>
          <Button
            click={() => window.open("https://wa.me/+5527996197164", "_blank")}
          >
            Entre em Contato
          </Button>
        </div>
        <button
          id="header-dropdown-btn"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <img src="/assets/icons/menu-burger.svg" alt="icone de menu" />
        </button>
      </div>
      <div
        id="dropdown-toggle"
        style={
          !isMobile || !showDropdown
            ? { display: "none" }
            : { display: "block" }
        }
        onClick={() => setShowDropdown(!showDropdown)}
      ></div>
    </header>
  );
};

export default Header;
