import "./ProductAccordion.css";
import { useState } from "react";

const ProductAccordion = ({ title, children }) => {
  const [toggleCollapse, setToggleCollapse] = useState(true);

  return (
    <div
      className="ProductAccordion"
      style={toggleCollapse ? { height: "calc(3rem + 2px)" } : {}}
      onClick={() => setToggleCollapse(!toggleCollapse)}
    >
      <h3>{title}</h3>
      <ul>{children}</ul>
      <img
        src="/assets/icons/caret-down.svg"
        alt="ícone de seta para baixo indicando a possibilidade de expandir o cartão"
        style={toggleCollapse ? {} : { transform: "rotate(180deg)" }}
      />
    </div>
  );
};

export default ProductAccordion;
