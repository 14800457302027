import "./Home.css";
import CountUp from "../../components/CountUp/CountUp";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import ProductAccordion from "../../components/ProductAccordion/ProductAccordion";
import Footer from "../../components/Footer/Footer";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const productSection = useRef(null);

  const [isPlaying, setIsPlaying] = useState(true);

  const pauseVideo = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) return;

    video.play().catch((error) => {
      console.error("Autoplay failed: ", error);
    });

    return () => {
      video.pause();
    };
  }, []);

  const carrText = [
    "Redução de custos",
    "Produtividade e Eficiência",
    "Automatização",
    "Plataforma simples de implantar e utilizar",
    "Melhor custo-benefício do mercado",
  ];

  const [carrPosition, setCarrPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarrPosition((prevNum) => (prevNum + 1) % 5);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header />

      <main>
        <section id="hero-banner">
          <div className="boundaries">
            <div id="hero-content">
              <h1>Transformando a gestão da sua empresa de saúde</h1>
              <div id="carroussel">
                <p className="dim">{carrText[carrPosition]}</p>
                <div id="carr-dots">
                  <div
                    style={
                      carrPosition === 0
                        ? { backgroundColor: "var(--green-light)" }
                        : { backgroundColor: "var(--gray-soft)" }
                    }
                  ></div>
                  <div
                    style={
                      carrPosition === 1
                        ? { backgroundColor: "var(--green-light)" }
                        : { backgroundColor: "var(--gray-soft)" }
                    }
                  ></div>
                  <div
                    style={
                      carrPosition === 2
                        ? { backgroundColor: "var(--green-light)" }
                        : { backgroundColor: "var(--gray-soft)" }
                    }
                  ></div>
                  <div
                    style={
                      carrPosition === 3
                        ? { backgroundColor: "var(--green-light)" }
                        : { backgroundColor: "var(--gray-soft)" }
                    }
                  ></div>
                  <div
                    style={
                      carrPosition === 4
                        ? { backgroundColor: "var(--green-light)" }
                        : { backgroundColor: "var(--gray-soft)" }
                    }
                  ></div>
                </div>
              </div>
              <Button click={() => scrollToSection(productSection)}>
                Veja as Funcionalidades
              </Button>
            </div>
            <HashLink to="/sobre-nos#centelha">
              <img
                src="/assets/img/centelha-medal.png"
                alt="imagem da logo do programa centelha dentro de uma medalha"
              />
            </HashLink>
          </div>
        </section>
        <section id="phrase">
          <div className="boundaries">
            <div id="bg-video">
              <video ref={videoRef} autoPlay muted loop playsInline>
                <source src="/assets/vid/video-15fps.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div id="video-overflow">
              <p className="center">
                Com a
                <img
                  src="/assets/img/lettering-white.svg"
                  alt="logo da marca"
                />
                a sua empresa vai ter <br />
                mais tempo para focar no que realmente importa:
              </p>
              <h3 className="center">Cuidar de quem precisa</h3>
            </div>
            <button
              id="playstate-btn"
              type="button"
              onClick={() => pauseVideo()}
            >
              {isPlaying ? (
                <img src="/assets/icons/pause.svg" alt="icone de pause" />
              ) : (
                <img src="/assets/icons/play.svg" alt="icone de play" />
              )}
            </button>
          </div>
        </section>
        <section id="customer-fields">
          <div className="boundaries">
            <h2 className="sect-title">Quem Atendemos</h2>
            <div>
              <div>
                <div>
                  <img src="/assets/icons/heart.svg" alt="icone de coração" />
                </div>
                <div>
                  <h3>Assistência Domiciliar</h3>
                  <p>
                    Gerencie suas operações de forma eficiente. Nossas
                    ferramentas permitem que você agende visitas, colete
                    feedbacks, mantenha registros clínicos eletrônicos e muito
                    mais!
                  </p>
                  <Button
                    color="orange"
                    click={() => navigate("/assistencia-domiciliar")}
                  >
                    Saiba Mais
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <img src="/assets/icons/house.svg" alt="icone de casa" />
                </div>
                <div>
                  <h3>Laboratórios com Serviço Domiciliar</h3>
                  <p>
                    Nossa plataforma facilita o agendamento online, a logística
                    de visitas e a comunicação entre técnicos e gestores,
                    garantindo que os procedimentos de vacinação e coleta sejam
                    realizados de maneira eficiente e segura.
                  </p>
                  <Button click={() => navigate("/labs-domiciliares")}>
                    Saiba Mais
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src="/assets/icons/stethoscope.svg"
                    alt="icone de estetoscópio"
                  />
                </div>
                <div>
                  <h3>Clínicas Multidisciplinares</h3>
                  <p>
                    Com nossa plataforma, é fácil marcar consultas, documentar
                    progressos clínicos e compartilhar informações de forma
                    segura, melhorando a eficiência operacional da clínica.
                  </p>
                  <Button
                    color="green"
                    click={() => navigate("/clinicas-multidisciplinares")}
                  >
                    Saiba Mais
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="secondaries">
          <div className="boundaries">
            <h2 className="mini-title">Seguimentos Secundários</h2>
            <div>
              <div
                onClick={() =>
                  window.open("https://wa.me/+5527996197164", "_blank")
                }
              >
                <img src="/assets/icons/paw.svg" alt="ícone de pata animal" />
                <h3>Clínicas Veterinárias</h3>
              </div>
              <div
                onClick={() =>
                  window.open("https://wa.me/+5527996197164", "_blank")
                }
              >
                <img
                  src="/assets/icons/stethoscope-blue.svg"
                  alt="ícone de estetoscópio"
                />
                <h3>Auditorias Médicas</h3>
              </div>
              <div
                onClick={() =>
                  window.open("https://wa.me/+5527996197164", "_blank")
                }
              >
                <img src="/assets/icons/house.svg" alt="ícone de casa" />
                <h3>Casas de Repouso e Asilos</h3>
              </div>
              <div
                onClick={() =>
                  window.open("https://wa.me/+5527996197164", "_blank")
                }
              >
                <img
                  src="/assets/icons/hands-heart.svg"
                  alt="ícone de mãos segurando coração"
                />
                <h3>Clínicas de Reabilitação</h3>
              </div>
            </div>
          </div>
        </section>
        <section id="product" ref={productSection}>
          <div className="boundaries">
            <h2 className="sect-title">Nosso Produto</h2>
            <p>
              Desenvolvida com foco nas necessidades de empresas de saúde
              domiciliar, laboratórios e clínicas multidisciplinares, nossa
              plataforma proporciona uma solução integrada e intuitiva. Ela
              permite que nossos clientes aprimorem sua eficiência operacional,
              reduzam custos e ofereçam um atendimento de alta qualidade aos
              pacientes.
            </p>
            <div>
              <img
                src="/assets/img/wireframe-illustration.png"
                alt="wireframe do aplicativo Geeo"
              />
              <div>
                <ProductAccordion title="Integração e Segurança">
                  <li>
                    Interoperabilidade nativa (sem necessidade de novos
                    desenvolvimentos)
                  </li>
                </ProductAccordion>
                <ProductAccordion title="Gestão e Segurança">
                  <li>Marketplace de serviços online</li>
                </ProductAccordion>
                <ProductAccordion title="Economia e Redução de Custos">
                  <li>
                    Redução de retrabalho devido a erros e uso de planilhas
                  </li>
                  <li>Redução de faltas nos agendamentos</li>
                  <li>Redução do uso de papel</li>
                </ProductAccordion>
                <ProductAccordion title="Agendamento">
                  <li>Agendamento online integrado com assistente de rotas</li>
                  <li>Confirmação de agenda</li>
                </ProductAccordion>
                <ProductAccordion title="Inteligência de Dados">
                  <li>Questionários dinâmicos</li>
                  <li>Integração com Estoque e Farmácia</li>
                </ProductAccordion>
                <ProductAccordion title="Geolocalização">
                  <li>
                    Assistente inteligente de rota para otimização de serviços
                    domiciliares
                  </li>
                  <li>Aplicativo offline para uso remoto</li>
                  <li>Acompanhamento em tempo real da equipe</li>
                  <li>Validação espacial nos atendimentos</li>
                </ProductAccordion>
                <ProductAccordion title="Estoque e Farmácia">
                  <li>Alertas por quantidade e vencimento de estoque</li>
                  <li>Importação de NF-e</li>
                  <li>Gestão de múltiplas unidades com estoques separados</li>
                  <li>Rastreabilidade de administração de medicamentos</li>
                  <li>Relatórios de movimentação e vencimento</li>
                </ProductAccordion>
                <ProductAccordion title="Estação de Cuidados">
                  <li>Múltiplas salas de medicação</li>
                  <li>Administração de medicamentos com rastreabilidade</li>
                  <li>
                    Registro de Alergias e avaliações clínicas (protocolos
                    flexíveis)
                  </li>
                  <li>Armazenamento e compartilhamento de arquivos médicos</li>
                  <li>Fila de espera de pacientes</li>
                </ProductAccordion>
              </div>
            </div>
            <Button
              click={() =>
                window.open("https://wa.me/+5527996197164", "_blank")
              }
            >
              Solicite um orçamento!
            </Button>
          </div>
        </section>
        <section id="beneficts">
          <div className="boundaries">
            <h2 className="sect-title">Por que escolher a GEEO?</h2>
            <div id="cards">
              <div>
                <h3>Redução de Custos</h3>
                <p>
                  Redução prevista de até <span className="bold">30%</span> nas
                  despesas.
                </p>
              </div>
              <div>
                <h3>Inteligência de Dados</h3>
                <p>
                  Informatização de dados clínicos trazendo Segurança e
                  Agilidade.
                </p>
              </div>
              <div>
                <h3>Organização de Processos</h3>
                <p>
                  Centralizamos informações de prestadores, pacientes e gestores
                  na mesma plataforma.
                </p>
              </div>
              <div>
                <h3>Acompanhamento em Tempo Real</h3>
                <p>Descrição breve do tópico em falta.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="numbers">
          <div className="boundaries">
            <div>
              <div>
                <p>
                  <CountUp targetNumber={25} />%
                </p>
                <p>Aumento de Eficiência</p>
              </div>
              <div>
                <p>
                  <CountUp targetNumber={32} />%
                </p>
                <p>Qualidade do Operacional</p>
              </div>
              <div>
                <p>
                  <CountUp targetNumber={30} />%
                </p>
                <p>Redução de Custos</p>
              </div>
              <div>
                <p>
                  <CountUp targetNumber={27} />%
                </p>
                <p>Otimização de Agendamentos</p>
              </div>
            </div>
            <img
              src="/assets/img/nurse-smiling.svg"
              alt="ilustração de mulher sorrindo"
            />
          </div>
        </section>
        <section id="free-demo">
          <div className="boundaries">
            <h3>
              Interessado em saber como podemos transformar a gestão de sua
              empresa?
            </h3>
            <p>
              Agende uma <span className="bold">demonstração gratuita</span> e
              descubra todas as funcionalidades que podem otimizar suas
              operações e melhorar a qualidade do atendimento.
            </p>
            <Button
              click={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=+5527996197164&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20a%20avalia%C3%A7%C3%A3o%20gratuita%21",
                  "_blank"
                )
              }
            >
              Solicitar Demonstração
            </Button>
          </div>
        </section>
        <section id="faq-reminder">
          <div className="boundaries">
            <h3>Alguma dúvida?</h3>
            <p>
              Confira nossa seção de perguntas frequentes (FAQ) para mais
              informações, ou se preferir, entre em contato conosco pelo
              WhatsApp. Estamos aqui para ajudar!
            </p>
            <div>
              <Button
                click={() =>
                  window.open("https://wa.me/+5527996197164", "_blank")
                }
              >
                Converse conosco
              </Button>
              <Button click={() => navigate("/faq")}>Abrir o FAQ</Button>
            </div>
          </div>
        </section>

        <a
          id="whatsapp-floaty"
          href="https://wa.me/+5527996197164"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/icons/whatsapp.svg" alt="icone do whatsapp" />
        </a>
      </main>

      <Footer />
    </>
  );
};

export default Home;
