import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, AboutUs, FAQ, AssistDom, LabsDom, Multi } from '../pages';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'; // ajuste o caminho conforme necessário

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre-nos" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/assistencia-domiciliar" element={<AssistDom />} />
        <Route path="/labs-domiciliares" element={<LabsDom />} />
        <Route path="/clinicas-multidisciplinares" element={<Multi />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
