import "./AboutUs.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const AboutUs = () => {
  return (
    <>
      <Header />

      <main className="AboutUs">
        <section>
          <div className="boundaries">
            <img src="/assets/img/logo.svg" alt="logomarca GEEO" />
            <img src="/assets/img/lettering.svg" alt="letreiro GEEO" />
            <h1 className="sect-title">Sobre Nós</h1>
            <p>
              HealthTech na área de gestão para pequenas e médias empresas do
              ramo da saúde. Desenvolvemos soluções tecnológicas que transformam
              as rotinas de operações dessas empresas, proporcionando uma gestão
              mais eficiente e integrada.
            </p>
            <br />
            <br />
            <p className="center emphasize">
              Com um histórico sólido no desenvolvimento de soluções
              tecnológicas, a GEEO surge para atender a crescente demanda por
              softwares inteligentes acessíveis
            </p>
            <br />
            <br />
            <br />
            <div id="mission-vision">
              <div>
                <h3>Missão</h3>
                <p>
                  Nossa missão é simplificar a rotina do nosso cliente,
                  fornecendo ferramentas que o ajudem a atender melhor os
                  pacientes, reduzir custos operacionais e aumentando sua
                  competitividade no mercado.
                </p>
              </div>
              <div>
                <h3>Visão</h3>
                <p>
                  Ser uma plataforma de gestão de saúde inovadora e confiável,
                  com capacidade de transformar a operação de pequenas e médias
                  empresas, contribuindo para um atendimento mais humano e
                  eficiente.
                </p>
              </div>
            </div>
            <br />
            <h4>Valores</h4>
            <ul>
              <li>
                <span className="bold">Inovação </span>- Constantemente
                evoluImos nossa tecnologia para oferecer as melhores soluções.
              </li>
              <li>
                <span className="bold">Segurança </span>- Priorizamos a
                segurança dos dados e a privacidade das informações dos nossos
                clientes.
              </li>
              <li>
                <span className="bold">Acessibilidade </span>- Facilitamos o
                acesso à nossa plataforma com uma interface intuitiva e suporte
                contínuo.
              </li>
              <li>
                <span className="bold">Eficiência </span>- Ajudamos empresas a
                otimizar seus processos e recursos para oferecer um melhor
                atendimento.
              </li>
              <li>
                <span className="bold">Colaboração </span>- Valorizamos o
                feedback e a parceria com nossos clientes para aprimorar nossos
                serviços.
              </li>
              <li>
                <span className="bold">Compromisso </span>- Estamos
                comprometidos com a qualidade e a excelência em tudo o que
                fazemos.
              </li>
            </ul>
            <br />
            <br />
            <br />
            <h2 id="centelha" className="mini-title">
              Premiação
            </h2>
            <p>
              A GEEO foi vencedora da 2&ordf; edição do programa{" "}
              <span className="bold">Centelha - BA</span>.
            </p>
            <img
              id="logo-centelha"
              src="/assets/img/logo-centelha.png"
              alt="logomarca Centelha"
            />
            <small>
              O Programa Centelha visa estimular a criação de empreendimentos
              inovadores e disseminar a cultura empreendedora na Bahia.
            </small>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AboutUs;
