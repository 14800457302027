import "./Vantagens.css"; // Importa o CSS
import icon1 from "../../src-assets/Vector.svg";
import icon2 from "../../src-assets/people-arrows-solid 1.svg";
import icon3 from "../../src-assets/comments-solid 1.svg";
import icon4 from "../../src-assets/file-circle-check-solid 1.svg";
import icon5 from "../../src-assets/calendar-check-solid 2.svg";
import icon6 from "../../src-assets/hospital-user-solid 1.svg";
import icon7 from "../../src-assets/user-doctor-solid 1.svg";
import icon8 from "../../src-assets/users-solid 2.svg";
import icon9 from "../../src-assets/house-medical-circle-check-solid 2.svg";

const Vantagens = ({ title, description, items }) => {
  const renderIcon = (iconName) => {
    switch (iconName) {
      case "icon1":
        return <img src={icon1} alt="Icon 1" />;
      case "icon2":
        return <img src={icon2} alt="Icon 2" />;
      case "icon3":
        return <img src={icon3} alt="Icon 3" />;
      case "icon4":
        return <img src={icon4} alt="Icon 4" />;
      case "icon5":
        return <img src={icon5} alt="Icon 5" />;
      case "icon6":
        return <img src={icon6} alt="Icon 6" />;
      case "icon7":
        return <img src={icon7} alt="Icon 7" />;
      case "icon8":
        return <img src={icon8} alt="Icon 8" />;
      case "icon9":
        return <img src={icon9} alt="Icon 9" />;
      default:
        return null;
    }
  };

  const renderText = (text, type) => {
    switch (type) {
      case "title":
        return <h3>{text}</h3>;
      case "subtitle":
        return <h4>{text}</h4>;
      default:
        return null;
    }
  };

  return (
    <div className="vantagens-container">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="vantagens-items">
        {items.map((item, index) => (
          <div className="vantagem-item" key={index}>
            <div className="vantagem-icon">{renderIcon(item.icon)}</div>
            {renderText(item.title, "title")}
            {renderText(item.subtitle, "subtitle")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vantagens;
