import "./AssistDom.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Vantagens from "../../components/Vantagens/Vantagens";
import img from "../../src-assets/looking-at-tablet2.jpg";

const AssistDom = () => {
  const items = [
    {
      icon: "icon1",
      title: "Otimização de rotas e agendamentos",
      subtitle:
        "Reduza o tempo e os custos associados ao transporte e maximize a eficiência das visitas domiciliares",
    },
    {
      icon: "icon2",
      title: "Comunicação interna aprimorada",
      subtitle:
        "Facilite a comunicação entre sua equipe e garanta que todos estejam na mesma página, aumentando a coordenação e a qualidade do atendimento",
    },
    {
      icon: "icon3",
      title: "Feedback contínuo",
      subtitle:
        "Coleta de feedbacks dos pacientes e familiares para melhorar continuamente a qualidade do serviço",
    },
    {
      icon: "icon4",
      title: "Registros clínicos eletrônicos",
      subtitle:
        "Mantenha registros detalhados e organizados, seguindo protocolos específicos, e elimine o uso de papel e planilhas",
    },
  ];
  return (
    <>
      <Header />

      <main class="AssistDom">
        <div class="img-texto">
          <div class="div-img">
            <img src="/assets/icons/heart.svg" alt="icone de coração" />
          </div>
          <div class="texto">
            <h3>Para sua empresa de assistência domiciliar</h3>
            <p>
              As soluções da GEEO integram dados e processos para melhorar a
              eficiência, comunicação e qualidade do atendimento, ajudando sua
              empresa a oferecer serviços de saúde domiciliar de forma mais
              eficaz e estratégica.
            </p>
          </div>
        </div>
        <Vantagens
          title="Vantagens"
          description="Conte com a Geeo para proporcionar vantagens para a gestão e otimização de comunicação em sua empresa!"
          items={items}
        />
        <section class="lastContent">
          <div class="lastText">
            <h2>Utilidades adicionais</h2>
            <h3>Gestão de Técnicos Extras para Atendimentos Pontuais</h3>
            <p>
              Quando há uma demanda inesperada de pacientes, a GEEO permite que
              você rapidamente agende técnicos extras, evitando atrasos e
              mantendo a qualidade do serviço
            </p>
            <h3>Facilidade para Encontrar a Residência do Paciente</h3>
            <p>
              Com o recurso de rastreamento em tempo real, os técnicos recebem
              as rotas otimizadas diretamente em seus dispositivos móveis,
              reduzindo o tempo de deslocamento e evitando atrasos.
            </p>
            <h3>Evoluções Clínicas em Dados Estruturados</h3>
            <p>
              Técnicos e enfermeiros podem registrar as evoluções clínicas dos
              pacientes diretamente na plataforma, garantindo que todas as
              informações estejam organizadas e acessíveis em um único lugar.
            </p>
          </div>
          <div class="assisDomtImg">
            <img src={img} alt="img" />
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AssistDom;
