import "./FAQ.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import FAQAccordion from "../../components/FAQAccordion/FAQAccordion";
import data from "./FAQ-content.json";
import faqIMG from "../../src-assets/faqImg.svg";

const FAQ = () => {
  const questions = data.questions;

  return (
    <>
      <Header />

      <main className="FAQ">
        <section>
          <div className="boundaries">
            <h1 className="sect-title">Perguntas Frequentes</h1>
            {questions.map((question) => {
              return <FAQAccordion title={question[0]} answer={question[1]} />;
            })}
          </div>
        </section>
        <div class="faq-img">
          <img src={faqIMG} alt="imagem faq" />
        </div>
      </main>

      <Footer />
    </>
  );
};

export default FAQ;
