import "./Button.css";

const Button = ({ click, color, children }) => {
  return (
    <button
      onClick={click}
      type="button"
      className={
        color === "orange"
          ? "Button orange"
          : color === "green"
          ? "Button green"
          : "Button"
      }
    >
      {children}
    </button>
  );
};

export default Button;
